<template>
  <div>
    <slot name="breadcrumbs" />
    <div
      class="page-title"
    >
      <slot name="title-page" />
    </div>
    <control-panel
      :label-search="searchLabel"
      v-bind:search-string.sync="options.searchString"
      :chips="chips"
      @show-filter="filterIsShow = true"
      @show-sort="sortIsShow = true"
      @submit-search="submitSearch"
      @delete-filter="deleteFilter"
    />
    <error-message
      v-if="errorMessage"
    >{{ errorMessage }} </error-message>
    <spinner
      v-if="!list && !errorMessage && spinnerIsShow"
    />
    <div
      style="position: relative"
      v-if="!errorMessage"
    >
      <v-navigation-drawer
        right
        absolute
        temporary
        width="400"
        height="auto"
        v-model="sortIsShow"
      >
        <sort-form
          class="sort"
          :sort-options="filterOptions"
          :in-filters="options.filters"
          :key="Date.now()"
          @submit="submitSort"
          @close="sortIsShow = false"
        />
      </v-navigation-drawer>
      <v-navigation-drawer
        right
        absolute
        temporary
        width="400"
        height="auto"
        v-model="filterIsShow"
      >
        <filter-form
          :filter-options="filterOptions"
          :in-filters="options.filters"
          :key="Date.now()"
          @submit-filter="submitFilter"
          @close="filterIsShow = false"
        />
      </v-navigation-drawer>
      <slot />
      <div
        class="message"
        v-if="!spinnerIsShow
            && !errorMessage
            && count === 0
        ">Записи, удовлетворяющие критериям поиска отсутствуют</div>
      <pagination
        v-if="list && list.length > 0"
        :page-count="countPage"
        :page="page"
        :count-all="count"
        :max-count-on-page="options.pageCount"
        @check-page="setPage"
        @change-max-count="changeMaxCount"
      />
    </div>
  </div>
</template>

<script>
// import Chips from './chip/Chips'
import ListMixin from '../mixins/ListMixin'
import { FILTER_SRO_STATE_VALUES, FILTER_TYPE_EQ, FILTER_TYPE_LIKE } from '../helpers/dictionary'
// import { loadSroListOptions, saveSroListOptions } from '../helpers/history'
// import { getDictionaries, getSroList } from '../helpers/api'
// import { formatDateShort } from '../helpers/format'
import FilterForm from './filter/FilterForm'
import SortForm from './sort/SortForm'
import Spinner from './spinner/Spinner'
import ErrorMessage from './alerts/ErrorMessage'
import Pagination from './pagination/Pagination'

export default {
  name: 'MainList',
  props: {
    searchLabel: {
      type: String,
      required: true
    },
    responseList: {
      type: Object,
      default: null
    }
  },
  components: {
    FilterForm,
    SortForm,
    Spinner,
    ErrorMessage,
    Pagination
  },
  mixins: [ListMixin],
  data: () => ({
    filterOptions: [
      {
        name: 'state',
        title: 'Статус',
        type: FILTER_TYPE_EQ,
        values: FILTER_SRO_STATE_VALUES
      },
      {
        name: 'registration_number',
        title: 'Регистрационный номер',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'full_description',
        title: 'Полное наименование СРО',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'place',
        title: 'Адрес',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'city',
        title: 'Субъект РФ',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'federal_district_id',
        title: 'Федеральный округ',
        type: FILTER_TYPE_EQ,
        values: []
      }
    ],
    page: 1,
    countPage: 0,
    count: 0,
    spinnerIsShow: false,
    chips: [],
    errorMessage: '',
    filterIsShow: false,
    sortIsShow: false,
    searchString: '',
    list: null,
    options: {
      filters: {},
      page: 1,
      pageCount: 2,
      sortBy: {}
    }
  }),
  methods: {
    // saveOptions: saveSroListOptions,
    // loadOptions: loadSroListOptions,
    // async getDataList () {
    //   return await getSroList(this.options)
    // },
    // async getDictionaries () {
    //   this.errorMessage = ''
    //   const requestData = await getDictionaries({ dictionaries: ['federal_districts'] })
    //
    //   if (!this.validateRequestData(requestData)) {
    //     return
    //   }
    //
    //   this
    //     .filterOptions
    //     .find(el => el.name === 'federal_district_id')
    //     .values = requestData.data.federal_districts
    // },
    async getList () {
      // console.log('Main list created')
      this.$emit('get-response-list')
      // this.errorMessage = ''
      // this.saveOptions(this.options)
      // this.spinnerIsShow = true
      //
      // const requestData = await this.getDataList()
      // this.spinnerIsShow = false
      //
      // if (!this.validateRequestData(requestData)) {
      //   return
      // }
      //
      // this.list = requestData.data.data
      // this.page = requestData.data.page
      // this.count = requestData.data.count
      // this.countPage = requestData.data.countPages
    }
    // async sortBy (fieldName) {
    //   if (!this.options.sortBy[fieldName]) {
    //     this.options.sortBy[fieldName] = 'ASC'
    //   } else if (this.options.sortBy[fieldName] === 'ASC') {
    //     this.options.sortBy[fieldName] = 'DESC'
    //   } else {
    //     delete this.options.sortBy[fieldName]
    //   }
    //   await this.getList()
    // },
    // async submitFilter (filters) {
    //   this.options.filters = filters
    //   this.filtersToChips()
    //   this.options.page = 1
    //   this.filterIsShow = false
    //   await this.getList()
    // },
    // async submitSort (sort) {
    //   this.options.sortBy = sort
    //   this.sortIsShow = false
    //   await this.getList()
    // },
    // async deleteFilter (filterName) {
    //   this.controlFilters()
    //   delete this.options.filters[filterName]
    //   await this.submitFilter(this.options.filters)
    // },
    // async setPage (numberPage) {
    //   this.controlFilters()
    //   this.options.page = numberPage
    //   await this.getList()
    // },
    // filtersToChips () {
    //   this.chips = []
    //   for (const filterName in this.options.filters) {
    //     if (!this.options.filters[filterName]) { continue }
    //     if (this.options.filters[filterName].length === 0) { continue }
    //
    //     const filterOptionItem = this.filterOptions.find(el => el.name === filterName)
    //     const title = filterOptionItem.title
    //     this.chips.push({
    //       name: filterName,
    //       value: this.getValueChip(filterName),
    //       title
    //     })
    //   }
    // },
    // controlFilters () {
    //   for (const filterItem in this.options.filters) {
    //     if (!this.chips.find(el => el.name === filterItem)) {
    //       delete this.options.filters[filterItem]
    //     }
    //   }
    // },
    // getValueChip (filterName) {
    //   const filterOptionItem = this.filterOptions.find(el => el.name === filterName)
    //   if (filterOptionItem.type === FILTER_TYPE_DATE) {
    //     return this.options.filters[filterName].map(el => formatDateShort(new Date(el))).join(' - ')
    //   }
    //
    //   if (filterOptionItem.values) {
    //     return filterOptionItem.values.find(el => el.id === this.options.filters[filterName]).title
    //   }
    //
    //   return this.options.filters[filterName]
    // },
    // async changeMaxCount (count) {
    //   this.options.pageCount = count
    //   this.options.page = 1
    //   await this.getList()
    // },
    // submitSearch () {
    //   if (!this.searchString) {
    //     return
    //   }
    //   console.log(this.searchString)
    // }
  },
  async created () {
    // this.options = this.loadOptions()
    // await this.getDictionaries()
    // this.filtersToChips()
    await this.getList()
  },
  watch: {
    responseList (value) {
      // console.log('watch', value)
    }
  }
}
</script>

<style scoped>

</style>
