<template>
  <main-list
    :response-list="responseList"
    @get-response-list="getList"
  >
    <sro-table :list="list" :options="options" />
  </main-list>
</template>

<script>
import MainList from '../../components/MainList'
import SroTable from '../../components/sro/SroTable'
import { getSroList } from '../../helpers/api'
export default {
  name: 'SroListTest',
  data () {
    return {
      responseList: {},
      options: {}
    }
  },
  components: {
    MainList,
    SroTable
  },
  methods: {
    async getList () {
      // console.log('get list')
      const responseData = await getSroList(this.options)
      this.responseList = responseData
    }
  }
}
</script>

<style scoped lang="sass">
//@import '../../assets/css/style'
</style>
