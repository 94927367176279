import { render, staticRenderFns } from "./MainList.vue?vue&type=template&id=8996770e&scoped=true"
import script from "./MainList.vue?vue&type=script&lang=js"
export * from "./MainList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8996770e",
  null
  
)

export default component.exports